@charset "UTF-8";

body,
html {
  height: 100%
}

body {
  font-family: '微软雅黑';
  background: #fff
}

.login-top {
  display: table;
  width: 100%;
  padding-left: 14%;
  margin: 0 auto
}

.login-footer,
.login-top {
  position: relative;
  height: 19%
}

.login-top .login-top-logo {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
  font-weight: bold;
}
.login-top .login-top-logo .logo{
  height: 60px;  
}

.login-content {
  width: 100%;
  height: 62%
}

.login-form-title {
  font-size: 20px;
  line-height: 38px;
  font-weight: 700
}

.login-content .login-form {
  position: absolute;
  top: 50%;
  right: 10%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  width: 27%;
  min-width: 300px;
  max-width: 530px;
  padding: 2%;
  color: #fff;
  border-radius: 2%;
  background: rgba(255, 255, 255, .8);
  -webkit-box-shadow: 0 2px 50px 0 rgba(0, 94, 170, .8);
  box-shadow: 0 2px 50px 0 rgba(0, 94, 170, .8)
}

.login-form .form-control {
  width: 100%;
  border: 0;
  background: #f8f8f8;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  height: 40px
}

.login-form .error {
  position: absolute;
  right: 40px;
  left: 40px;
  margin-top: -5px
}

.login-form .error-tips {
  display: none;
  text-align: center;
  line-height: 1;
  font-size: 12px;
  opacity: 1;
  color: #f60
}

.login-form .show {
  opacity: 1
}

.login-form .kaptchaLabel {
  display: block
}

.login-form #kaptcha {
  display: inline-block;
  width: 65%;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 5px
}

.login-form #kaptcha::-ms-clear {
  display: none
}

.login-form .fa-check-circle-o,
.login-form .fa-times-circle-o {
  position: absolute;
  font-size: 22px;
  display: inline-block;
  margin-top: 6px;
  margin-left: -32px;
  -webkit-transition: color .5s ease;
  transition: color .5s ease
}

.login-form .fa-check-circle-o {
  color: #8ece4d
}

.login-form .fa-times-circle-o {
  color: #fb4242
}

.login-form .checkbox,
.repwd {
  line-height: 16px;
  font-size: 14px
}

.login-form .checkbox {
  color: #9b9b9b;
  height: 16px;
  margin-top: -5px
}

.login-form input[type=checkbox] {
  position: relative;
  width: 20px
}

#remember {
  margin-right: 3px;
  margin-left: -15px;
  line-height: 16px
}

#remember img {
  width: 100%;
  display: none;
  margin-top: 0
}

#noRem,
#remember {
  display: inline-block;
  width: 16px;
  height: 16px
}

.repwd {
  margin-top: 0
}

.login-form #kaptcha+img {
  position: absolute;
  right: 40px;
  margin-top: -34px
}

.js-kaptcha {
  width: 33%;
  height: 40px
}

.login-form .login-btn {
  font-size: 20px;
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;
  border: 0;
  background: #007aff;
  height: 50px;
  line-height: 50px
}

.login-form .login-btn.disabled {
  background: #666;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed
}

.login-footer .copyright {
  position: absolute;
  top: 60%;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 16px;
  font-family: sans-serif;
}

/*# sourceMappingURL=login.css.map */

.beianicon:before {
  content: '';
  display: inline-block;    
  background-image: url('../images/备案图标.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  margin-right: 2px;
  transform: translateY(2px);
  }